import moment from 'moment';
import env from "../conf";

const Services = {

    /**
     * reformat a string to have the first char uppercase
     * @param string
     * @returns {string}
     */
    reformatString(string){
        let word = string[0].toUpperCase() + string.slice(1).toLowerCase();
        return word;
    },

    /**
     * check if a user is logged in
     * @param user
     * @param props
     * @param link
     * @param redirect
     */
    checkIfLoggedIn(user, props, link, redirect){
        if(user){
            if(redirect) props.push(link);
        }else{
            props.push('/login')
        }
    },

    getUser(){
        let user = JSON.parse(localStorage.getItem('user'));

        return user
    },

    /**
     * check if an array contain a value, --not case sensitive
     * @param sentence
     * @param str
     * @returns {boolean}
     */
    doesContain(sentence, str){
        let new_sentence = sentence.toLowerCase();
        let new_string = str.toLowerCase();

        return new_sentence.includes(new_string)
    },

    /**
     * check if an object is empty
     * @param obj
     * @returns {boolean}
     */
    isObjEmpty(obj){
        return Object.keys(obj).length === 0 && obj.constructor === Object
    },

    /**
     * Getting any url param value
     * @param name
     * @param url
     * @returns {string|null}
     */
    getPramValue(name, url) {
        name = name.replace(/[[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },

    /**
     * this used for the chart, since running math operation inside the chart component returns DOM errors
     * // TODO this is a bug needs to be reported to the original developers of PieChart
     * @param one
     * @param two
     * @returns {number}
     */
    subscract(one, two){
        return one - two;
    },

    /**
     * formatting numbers to check if to show the decimal numbers or not + limiting them to 2 digits
     * @param num
     * @returns {string}
     */
    formatPrice(num, status){
        if(status){
            return env.hide_numbers_text;
        }else{
            //if(typeof num === "object") console.log(num)
            return num % 1 === 0 ? num : num.toFixed(2);
        }

    },

    /**
     * Check if user client is Safari not chrome
     * @returns {boolean}
     */
    isSafari(){
        return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
    },

    /**
     * calculating the average of a value per day, days calculated depends on from and to provided dates
     * @param from
     * @param to
     * @param total
     * @returns {number}
     */
    getAveragePerDay(from, to, total){

        let a = moment(from);
        let b = moment(to);
        let difference = b.diff(a, 'days')

        return total / difference;
    },

    /**
     * Check if a service can use credit as payment or not
     * @param service
     * @returns {boolean}
     */
    canServiceUseCredit(service){
        return service !== '30min Touch-Up' && service !== 'Correction' && service !== 'Wax';
    },

    /**
     * Remove duplicated objects from a list based on a property name
     * @param myArr
     * @param prop
     * @returns {*}
     */
    removeDuplicates(myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    },
    calculateIncome(income){
        return Services.calculateRetail(income) + Services.calculateService(income);
    },

    /**
     * calculate retails only of a specific income
     * @param income
     * @returns {number}
     */
    calculateRetail(income){

        let total_retail = 0;

        if(income.products && income.products.length > 0){
            for(let product of income.products) {
                total_retail += product.price * product.qty
            }
        }

        return total_retail;

    },

    /**
     * calculate Services + Tips only of a specific income
     * @param income
     * @returns {number}
     */
    calculateService(income){

        let total_services = 0;

        if(income.type !== 'product'){

            let amount = income.amount !== undefined ? income.amount : 0;
            let tip = income.tip  !== undefined ? income.tip : 0;

            total_services = amount + tip;
        }

        return total_services;
    },

    sortByLastVisit( a, b ) {
        if ( a.last_visit.start < b.last_visit.start ){
            return 1;
        }
        if ( a.last_visit.start > b.last_visit.start ){
            return -1;
        }
        return 0;
    },

    groupBy(xs, key) {
        return xs.reduce(function(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    },

    showAffiliate(str){
        return env.affiliate.includes(str);
    },

    isTech(user){
        return user.comp && user.comp.level === 'L2'
    },

    downloadCSV(csv_str, file_name){

        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv_str);
        hiddenElement.target = '_blank';

        //provide the name for the CSV file to be downloaded
        hiddenElement.download = file_name + '.csv';
        hiddenElement.click();
    },

    getLocation(){
        return localStorage.getItem(env.local.location) || 'gg1';
        /*let usr = JSON.parse(localStorage.getItem('user'));

        if(this.getUser()){
            return usr.location || 'gg1';
        }*/

    },

    getEmployeeName(id){
        let employee = env.all_employees.find(employee => employee.id === id);
        return employee.name
    }
};

export default Services;
