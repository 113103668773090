import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import Clients from "./pages/Clients";
import Header from "./layouts/Header";
import Home from "./pages/Home";
import Appointments from "./pages/Appointments";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import db, {auth} from "./Firestore";
import Accounting from "./pages/Accounting";
import Dashboard from "./pages/Dashboard";
import Menu from "./components/menu/Menu";
import Marketing from "./pages/Marketing";
import CampList from "./components/campaigns/CampList";
import Feedback from "./components/campaigns/feedback/Feedback";
import Leads from "./components/campaigns/leads/Leads";
import Settings from "./pages/Settings";
import Services from "./services/Services";
import Inventory from "./pages/Inventory";



class App extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            user: null,
            numbers: this.localNumbers,
            location: this.localLocation
        }
    }

    localNumbers = JSON.parse(localStorage.getItem('show_numbers'));
    localLocation = Services.getLocation();

    componentDidMount() {
        this.getLocalStorage();

        auth.onAuthStateChanged(user => {
            if(user){

                db.collection('users')
                    .doc(user.uid)
                    .get()
                    .then( usr => {

                        let my_user = usr.data();
                        localStorage.setItem('user', JSON.stringify(my_user));
                        this.setState({user: my_user})

                    })


            }else{
                this.setState({user : null}, cb => {
                })
            }
        });
    }

    getLocalStorage = () => {
        this.setState(
            {
                numbers: this.localNumbers,
                location: this.localLocation
            });
    };

    getUser = (user) => {
        this.setState({
            user: user
        })
    };

    showNumbers = (num) => {
        this.setState({
            numbers: num
        })
    };

    currentLocation = (lc) => {
        this.setState({
            location: lc
        })
    };

    render(){
        return (
            <Router>
                <div className='app'>
                    <Header
                        user={this.state.user}
                        show_num={this.showNumbers}
                        numbers={this.state.numbers}
                        current_lc={this.currentLocation}
                        lc={this.state.location}
                    />
                    <Route
                        path='/'
                        exact
                        render={(props) => <Home {...props} user={this.state.user} />}
                    />
                    <Route
                        path='/home'
                        exact
                        render={(props) => <Dashboard {...props} user={this.state.user} lc={this.state.location} />}
                    />
                    <Route
                        path='/login'
                        exact
                        render={(props) => <Login {...props} user={this.state.user} updateUser={this.getUser} />}
                    />
                    <Route
                        path='/logout'
                        exact
                        render={(props) => <Logout {...props} user={this.state.user} />}
                    />
                    <Route
                        path='/clients'
                        exact
                        render={(props) => <Clients {...props} user={this.state.user}  lc={this.state.location} />}
                    />
                    <Route
                        path='/appointments'
                        exact
                        render={(props) => <Appointments {...props} user={this.state.user} lc={this.state.location} />}
                    />
                    <Route
                        path='/accounting'
                        exact
                        render={(props) => <Accounting {...props} user={this.state.user} numbers={this.state.numbers} lc={this.state.location} />}
                    />
                    <Route
                        path='/marketing'
                        exact
                        render={(props) => <Marketing {...props} user={this.state.user} lc={this.state.location}/>}
                    />
                    <Route
                        path='/menu'
                        exact
                        render={(props) => <Menu {...props} user={this.state.user} />}
                    />
                    <Route
                        path='/campaigns'
                        exact
                        render={(props) => <CampList {...props} user={this.state.user} />}
                    />
                    <Route
                        path='/feedback'
                        exact
                        render={(props) => <Feedback {...props} user={this.state.user} />}
                    />
                    <Route
                        path='/leads'
                        exact
                        render={(props) => <Leads {...props} user={this.state.user} lc={this.state.location} />}
                    />
                    <Route
                        path='/settings'
                        exact
                        render={(props) => <Settings {...props} user={this.state.user} />}
                    />

                    <Route
                        path='/inventory'
                        exact
                        render={(props) => <Inventory {...props} user={this.state.user} />}
                    />

                    {/*<Route
                        path='/Affiliates'
                        exact
                        render={(props) => <Leads {...props} user={this.state.user} />}
                    />*/}

                </div>
            </Router>
        );
    }

}

export default App;
